import React from 'react';
import Gallery from './Gallery';

const AnimalsGallery = () => {
    return (
        <div>
            <Gallery />
        </div>
    );
};

export default AnimalsGallery;
